.container {
    .btns_container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .cancel_btn {
            background: linear-gradient(126.58deg, rgba(255, 255, 255, 0.15) 23.2%, rgba(255, 255, 255, 0) 71.3%),
                linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
            border: none;

            &:active,
            &:hover {
                border: none;
                background: linear-gradient(126.58deg, rgba(255, 255, 255, 0.15) 23.2%, rgba(255, 255, 255, 0) 71.3%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
            }
        }
    }
}