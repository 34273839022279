.text {
  margin-top: 10px;
  margin-bottom: 30px;
}

.btns_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .cancel_btn {
    width: 30%;
  }

  .submit_btn {
    width: 30%;
  }
}
