@import '../../styles/variables.scss';

.container {
  width: 100%;
  background-color: $color-grey-light;
  border: 1px solid rgba(207, 207, 252, 0.35);
  border-radius: 16px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  left: -850px;
  transition: left 2s ease;

  .loader {
    margin: 0px;
  }
}

.transition {
  padding: 15px 25px;
  left: 0px;
}
