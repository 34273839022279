$color-grey-black: #202020;
$color-grey-dark: #696969;
$color-grey-ligtest: #cfcfcf;
$color-yellow: #f9b949;
$color-grey-scale: #1c1c23;
$color-grey-scale-70: #353542;
$color-grey-scale-50: #666680;
$color-grey-scale-40: #83839c;
$color-grey-scale-30: #a2a2b5;
$color-grey-white: #ffffff;
$color-grey-scale-60: #4e4e61;
$color-grey-scale-10: #e0e0e6;
$color-grey-light: #26262f;
$color-grey-border: #adadad33;
$color-summary-grey: #c1c1cd;
$color-green: #00fad9;
$color-carrot: #ff7966;

$font-family-inter: inter;
