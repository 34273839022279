@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .back_btn {
  }

  .date {
    font-family: $font-family-inter;
    font-size: 16px;
    font-weight: 600;
  }

  .next_btn {
  }
}
