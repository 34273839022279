@import '../../styles//variables.scss';

.container {
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;

  .progress_bar {
    position: relative;
    width: 286px;
    height: 226px;
  }

  .semi_circle {
    position: absolute;
    top: 20px;
    left: 65px;
    height: 137px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 46px;

    .date {
      font-family: $font-family-inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: white;
    }

    .percentage {
      font-size: 30px;
      font-weight: 700;
      color: $color-grey-white;
      letter-spacing: 0.5px;
      color: green;
    }

    .content {
      font-size: 16px;
      font-weight: 700;
      color: $color-grey-white;
      letter-spacing: 0.5px;
      width: 125px;

      .tagline {
        margin-left: 7px;
        font-family: inter;
        font-size: 16px;
        font-weight: 600;
        color: white;
      }

      .selecteditem_container {
        display: flex;
        flex-direction: column;
        gap: 7px;

        .amount {
          font-family: $font-family-inter;
          font-size: 40px;
          font-weight: 700;
          line-height: 32px;
        }

        .meetings_count {
          font-family: $font-family-inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }

        .pekaboo_sales {
          font-family: $font-family-inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          margin-top: 4px;
        }

        .selecteditemname {
          font-family: $font-family-inter;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px;
        }
      }
    }

    .totalsale_container {
      .month_text {
        font-family: $font-family-inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
      }

      .heading {
        font-family: $font-family-inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
      }

      .amount_achieved {
        font-family: $font-family-inter;
        font-size: 27px;
        font-weight: 700;
        line-height: 35px;
      }
      .percentage_green {
        font-family: $font-family-inter;
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
        color: green;
      }

      .percentage_carrot {
        font-family: $font-family-inter;
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
        color: $color-carrot;
      }

      .amount_target {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $font-family-inter;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        margin-top: 6px;
      }
    }

    .rating {
      font-size: 12px;
      font-weight: 600;
      color: $color-yellow;
      letter-spacing: 0.5px;
    }

    .carousel_container {
      .carousal {
        .item {
          font-size: 12px;
          padding: 0px 36px 0px 36px;
          font-weight: 600;
        }
      }
    }

    .btn {
      letter-spacing: 0.5px;
      border-radius: 16px;
      padding: 8px 12px 8px 12px;
      background: #3d3d46;
      border: none;

      color: $color-grey-white;
      font-size: 12px;
      font-weight: 600;
    }

    .dropdown {
      display: flex;
      justify-content: center;
      gap: 10px;

      button {
        letter-spacing: 0.5px;
        border-radius: 16px;
        padding: 8px 12px 8px 12px;
        background: #3d3d46;
        border: none;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .home_page {
    top: 40px;
    gap: 20px;
  }

  .home_page_selecteditem {
    top: 30px;
    gap: 10px;
  }

  .home_page_selecteditem_pekaboo {
    top: 30px;
    gap: 8px;
  }

  .home_page_selecteditem_totalsale {
    top: 30px;
    gap: 18px;
  }

  .title {
    color: $color-grey-white;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 12px;
    margin-top: 45px;
  }
}
