.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  z-index: 10;

  .wrapperClass {
  }
}
