@import '../../../styles/variables.scss';

.container {
  background-color: rgba(78, 78, 97, 0.2);
  border-radius: 20px;
  border: 1.5px solid white;
  width: 100%;
  // height: 125px;
  padding: 16px;

  .card_heading {
    display: flex;
    justify-content: center;
    font-family: $font-family-inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .card {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .description {
        width: 40%;
        font-family: $font-family-inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #c1c1cd;
      }
      .amounts {
        width: 60%;
        display: flex;
        justify-content: end;
        gap: 2px;

        .ratio {
          width: 70%;
          font-family: $font-family-inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          color: #ffffff;
          text-align: end;
          border-right: 2px solid gray;
          padding-right: 10px;
        }

        .percentage {
          width: 30%;
          font-family: $font-family-inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          color: #ffffff;
          text-align: end;
        }

        // .avg_sale {
        //   width: 50%;
        //   font-family: $font-family-inter;
        //   font-size: 12px;
        //   font-weight: 600;
        //   line-height: 16px;
        //   color: #ffffff;
        //   margin-right: 44px;
        // }
      }
    }
  }

  .progressbar {
    margin-top: 16px;
  }
}
