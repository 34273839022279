@import '../../../styles/variables.scss';

.container {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;

  .label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: $color-grey-scale-50;
  }

  .input {
    width: 100%;
    height: 60px;
    border-radius: 16px;
    background-color: $color-grey-scale;
    border: 1px solid $color-grey-scale-70;
    color: $color-grey-white;
    padding: 12px 14px;
    position: relative;
    font-family: $font-family-inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    &:hover {
      border: 1px solid $color-grey-scale-70;
    }

    &:focus {
      border: 1px solid $color-grey-scale-70;
      outline: none;
    }
  }

  .endIcon {
    position: absolute;
    top: 38px;
    right: 17px;
  }
  .error_input {
    width: 100%;
    border-radius: 16px;
    background-color: $color-grey-scale;
    color: $color-grey-white;
    border: 1px solid red;
    padding: 12px 14px;
    position: relative;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .error {
    color: red;
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: white;
  font-family: $font-family-inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.25);
}

//for firefox
input[type='number'] {
  -moz-appearance: textfield;
}
