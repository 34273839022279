@import '../../styles/variables.scss';

.main {
  width: 100%;
  padding: 20px 20px;
  border: 1px solid #4e4e61;
  border-radius: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  gap: 20px;

  .sender_info {
    width: 35%;
    display: flex;
    align-items: center;
    gap: 10px;

    .notification_circle {
      min-width: 14px;
      min-height: 14px;
      border-radius: 7px;
      background-color: red;
    }

    .sender {
      font-family: $font-family-inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: white;
    }
  }

  .message_container {
    width: 65%;
    .message {
      font-family: $font-family-inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      @media screen and (max-width: 400px) {
        // max-width: 190px;
      }
    }
  }

  .time {
    font-size: 11px;
    color: gray;
    position: absolute;
    bottom: 2px;
    right: 20px;
  }
}
