@import '../../../styles/variables.scss';

.modal {
    .header {
        color: $color-grey-white;
        background-color: $color-grey-scale;
        padding: 18px;
        font-size: 12px;

        .title {
            font-size: 19px;
        }
    }

    .body {
        padding: 32px 22px;
        color: $color-grey-white;
        background-color: $color-grey-scale;
        border-radius: 6px;
        border-color: $color-grey-scale;
    }
}