@import '../../../styles/variables.scss';

.container {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
    background-color: $color-grey-scale;

    // @media screen and (min-width: 360px) {
    //     height: 100vh;
    // }

    .logo {
        margin: 72px 0px;
        width: 90.28px;
        height: 41px;
    }

    .content_container {
        width: 100%;

        .password_bar_container {
            display: flex;
            margin: 20px 0px;

            div {
                margin-right: 4px;
                border: 4px solid $color-grey-scale-70;
                width: 100%;
                transition: all 1s ease;
            }

            .poor_password {
                border: 4px solid red;
            }

            .medium_password {
                border: 4px solid #00bfff;
            }

            .strong_password {
                border: 4px solid yellow;
            }

            .very_strong_password {
                border: 4px solid rgb(79 255 0);
            }

            :first-child {
                border-radius: 9px 0px 0px 9px;
            }

            :last-child {
                border-radius: 0px 9px 9px 0px;
                margin-right: 0px
            }
        }

        .password_info {
            color: $color-grey-scale-50;
            font-size: 12px;
            font-weight: 500;
        }

        .get_started_btn_container {
            padding-top: 28px;
        }

        .get_started_btn {
            box-shadow: 0px 0px 6px 0px $color-yellow;
        }

        .accounts {
            text-align: center;
            padding: 82px 0px 22px;

            .already_account_heading {
                color: white;
                font-size: 14px;
                font-weight: 400;
            }

            .account_btn {
                background: linear-gradient(126.58deg, rgba(255, 255, 255, 0.15) 23.2%, rgba(255, 255, 255, 0) 71.3%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
                border: none;

                &:active,
                &:hover {
                    background: linear-gradient(126.58deg, rgba(255, 255, 255, 0.15) 23.2%, rgba(255, 255, 255, 0) 71.3%),
                        linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
                }
            }
        }
    }
}