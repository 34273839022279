.container {
  .btns_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;

    .cancel_btn {
      flex-basis: 48%;
      background: linear-gradient(
          126.58deg,
          rgba(255, 255, 255, 0.15) 23.2%,
          rgba(255, 255, 255, 0) 71.3%
        ),
        linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)
        );
      border: none;

      &:active,
      &:hover {
        border: none;
        background: linear-gradient(
            126.58deg,
            rgba(255, 255, 255, 0.15) 23.2%,
            rgba(255, 255, 255, 0) 71.3%
          ),
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
          );
      }
    }
    .cancel_btn_container {
      flex-basis: 48%;
    }
    .submit_btn {
      flex-basis: 48%;
    }

    @media screen and (max-width: 462px) {
      .submit_btn,
      .cancel_btn_container {
        flex-basis: 100%;
      }
    }
  }
}
