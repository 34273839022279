@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
body {
  max-width: 768px;
  margin: 0 auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  font-family: "Inter";
}

code {
  font-family: "Inter";
}
