@import '../../styles/variables.scss';

.container {
  width: 30%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // border: 2px solid #4e4e61;
  border-radius: 16px;
  padding: 25px 5px;
  margin-bottom: 10px;
  background-color: rgba(78, 78, 97, 0.2);
  position: relative;

  .top_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .heading {
      font-family: $font-family-inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
    }
  }

  .figures {
    display: flex;
    flex-direction: column;
    align-items: center;

    .target {
      font-family: $font-family-inter;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
    }

    .amount {
      font-family: $font-family-inter;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .top_border {
    position: absolute;
    top: 0;
    height: 1px;
    width: 45px;
    background-color: $color-carrot;
  }
}
