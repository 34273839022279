@import '../../styles/variables.scss';

.container {
    background-color: $color-grey-scale;
    color: white;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .outlet {
        padding-bottom: 100px;
    }

    .footer {
        padding: 0px 18px 12px;
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 768px;
        backdrop-filter: blur(8px);
    }
}