.container {
  .switch {
    input {
      width: 40px;
      height: 20px;

      &:checked {
        background-color: darkgray !important;
        border-color: darkgray !important;
      }
      &:focus {
        border-color: white;
        outline: none;
        box-shadow: none;
        background-image: '';
      }
    }
  }
}
