@import '../../styles/variables.scss';

.text {
  margin-top: 10px;
  margin-bottom: 30px;

  .input {
    padding: 17px 15px;
  }
}

.title {
  font-family: $font-family-inter;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
}
.btns_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;

  .copy_btn {
    width: 40%;
  }

  .open_btn {
    width: 40%;
  }
}
