@import '../../styles/variables.scss';

.container {
  background-color: rgba(53, 53, 66, 0.5);
  // height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 35px 10px;
  position: relative;
  // height: 88vh;
  // overflow-y: scroll;

  .logout_btn {
    cursor: pointer;
    position: absolute;
    top: 38px;
    right: 15px;
    // background-color: #414152;
    background-color: transparent;
    color: white;
    border: 0px solid #414152;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    // padding: 5px 15px 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .main_heading {
    font-family: $font-family-inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $color-grey-scale-30;
  }

  .date_carousal_container {
    .date_class {
    }
  }

  .staff_selector_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .select_main {
      width: 94px;
    }
  }

  .notifications_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .no_notification_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    .peekaboo_card {
      background-color: $color-green !important;
    }

    .onetoone_card {
      background-color: $color-yellow !important;
    }

    .sale_card {
      background-color: $color-carrot !important;
    }

    .activeapp_card {
      background-color: white !important;
    }
  }
}
