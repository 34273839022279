@import '../../styles/variables.scss';

.container {
  padding: 14px 12px;
  position: relative;

  .logout_btn {
    cursor: pointer;
    position: absolute;
    top: 38px;
    right: 15px;
    // background-color: #414152;
    background-color: transparent;
    color: white;
    border: 0px solid #414152;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    // padding: 5px 15px 8px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .back_btn_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    position: absolute;
    top: 38px;
    left: 15px;
    // background-color: #414152;
    // padding: 6px 15px;
    border-radius: 4px;
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: bold;

    .back_btn {
      width: 24px;
      height: 16.85px;
      cursor: pointer;
    }

    p {
      margin: 0px;
    }
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .heading_text {
      color: white;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .carousel_container {
    border-radius: 20px;
    border: 1px solid $color-grey-scale-60;
    padding: 5px 15px;

    a {
      width: auto;
      opacity: 1;
    }

    .item {
      font-family: $font-family-inter;
      font-size: 27px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
    }
  }

  .month_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 30px;

    .month {
      color: white;
      font-family: $font-family-inter;
      font-size: 14px;
    }
  }

  .targets {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;

    .carrot_top {
      background-color: $color-carrot;
    }

    .green_top {
      background-color: $color-green;
    }

    .yellow_top {
      background-color: $color-yellow;
    }
  }

  .in_center {
    justify-content: center;
    gap: 10px;
  }

  .select_buttons {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin: 10px 0px 20px 0px;
    position: relative;
    z-index: 100;

    // padding-bottom: 28px;
    button {
      background: #3d3d46;
      border: none;
      border-radius: 16px;
      padding: 8px;
      font-size: 12px;
    }

    .select_main {
      width: 94px;
    }
  }

  .guage_container {
    margin-top: 12px;
    position: relative;
    display: flex;
    justify-content: center;

    .guage_class {
      width: 286px;
      position: relative;

      .heading {
        position: absolute;
        bottom: 39%;
        left: 23%;
        font-size: 16px;
        font-weight: 600;
        color: $color-grey-white;
        width: 150px;
        display: flex;
        justify-content: center;
      }

      .label1 {
        position: absolute;
        top: -20px;
        left: 50%;
        font-size: 12px;
        font-weight: 500;
        color: $color-grey-scale-30;
      }

      .label2 {
        position: absolute;
        top: 30%;
        right: 10%;
        font-size: 12px;
        font-weight: 500;
        color: $color-grey-scale-30;
      }

      .label3 {
        position: absolute;
        top: 30%;
        left: 10%;
        font-size: 12px;
        font-weight: 500;
        color: $color-grey-scale-30;
      }
    }
  }

  .line_container {
    display: flex;
    justify-content: center;
    .line {
      width: 90%;
      margin-top: 10px;
      border-bottom: 1px solid #4e4e61;
    }
  }

  .stats_info_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0px;

    .panel_content_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .gm_message_error {
        font-family: $font-family-inter;
        font-size: 12px;
        color: red;
        text-align: center;
      }

      .input_container {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input_main {
          margin-bottom: 0px;
        }

        .input_field_left_aligned {
          padding: 16px 20px;
          text-align: left;
        }

        .input_field {
          padding: 16px 20px;
          text-align: center;
        }
      }
      .btn_container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .total_sales_btn {
          background-color: $color-carrot;
          border-radius: 16px;
          border: 1px solid $color-carrot;
          color: white;
          padding: 16px 0px;
        }

        .peekabo_amount_btn {
          background-color: $color-green;
          border-radius: 16px;
          border: 1px solid $color-green;
          color: black;
          padding: 16px 0px;
        }

        .peekabo_presentation_btn {
          background-color: #414152;
          border-radius: 16px;
          border: 1px solid #414152;
          padding: 16px 0px;
          color: $color-green;
        }

        .onetoone_amount_btn {
          background-color: $color-yellow;
          border-radius: 16px;
          border: 1px solid $color-yellow;
          color: black;
          padding: 16px 0px;
        }

        .onetoone_amount_btn_gm {
          background-color: $color-yellow;
          border-radius: 16px;
          border: 1px solid $color-yellow;
          color: black;
          padding: 16px 0px;
        }

        .onetoone_meeting_btn {
          background-color: #414152;
          border-radius: 16px;
          border: 1px solid #414152;
          padding: 16px 0px;
          color: $color-yellow;
        }
      }
    }

    .message_input_container {
      margin-top: 30px;
      .textarea {
        width: 100%;
        border-radius: 16px;
        border: 1px solid #4e4e61;
        background-color: #1c1c23;
        color: white;
        padding: 10px 16px 10px 16px;
        font-family: $font-family-inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        resize: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .save_btn_class {
    background-color: $color-grey-light;
    border-radius: 20px;
    padding: 14px 16px;
    font-size: 14px;
    font-weight: 600;
    color: $color-grey-white;
    border: 1px solid $color-grey-border;

    &:hover,
    &:active {
      background-color: $color-grey-light;
      border: 1px solid $color-grey-border;
    }
  }
}
