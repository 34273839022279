@import '../../styles/variables.scss';

.container {
  position: relative;
  text-align: center;
  border-radius: 16px;
  padding: 12px 6px;
  background-color: rgba(78, 78, 97, 0.2);
  width: 104px;
  height: 102px;
  border: 1px solid #adadad33;

  .icon {
    margin-bottom: 5px;
  }
  .border_top {
    position: absolute;
    height: 1px;
    width: 49px;
    left: 28%;
    top: 0px;
  }

  .text {
    font-family: $font-family-inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: white;
  }

  .target_heading {
    font-family: $font-family-inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    color: $color-grey-scale-10;
  }

  .img {
    width: 28px;
    height: 28px;
    margin-bottom: 6px;
  }

  .ratio {
    font-size: 14px;
    font-weight: 600;
    color: $color-grey-white;
  }
}
