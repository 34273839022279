@import '../../styles/variables.scss';

@keyframes moveBorder {
  0% {
    border-top: 2px solid $color-yellow;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }

  25% {
    border-top: 2px solid transparent;
    border-right: 2px solid $color-yellow;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }

  50% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid $color-yellow;
    border-left: 2px solid transparent;
  }

  75% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid $color-yellow;
  }

  100% {
    border-top: 2px solid $color-yellow;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
  }
}

.container_main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 700px;
  .main {
    position: relative;

    .logout_btn {
      cursor: pointer;
      position: absolute;
      top: 38px;
      right: 15px;
      // background-color: #414152;
      background-color: transparent;
      color: white;
      border: 0px solid #414152;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 600;
      // padding: 5px 15px 8px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .user_name_container {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 229px;

      .user_name {
        letter-spacing: 0.5px;
        border-radius: 16px;
        padding: 8px 12px 8px 12px;
        background: #3d3d46;
        border: none;
        color: $color-grey-white;
        font-size: 12px;
        font-weight: 600;
        z-index: 1;
      }
    }

    .back_btn_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      position: absolute;
      top: 38px;
      left: 15px;
      // background-color: #414152;
      // padding: 6px 15px;
      border-radius: 4px;
      font-family: $font-family-inter;
      font-size: 13px;
      font-weight: bold;

      .back_btn {
        width: 24px;
        height: 16.85px;
        cursor: pointer;
      }

      p {
        margin: 0px;
      }
    }

    .container {
      padding: 22px 0px;
      background-color: #282832;
      border-radius: 0px 0px 24px 24px;

      .circle_progress_bar_container {
        padding: 52px 0px 0px;
        .green_rating {
          color: #00fad9;
        }
        .red_rating {
          color: #ff7966;
        }
        .main_rating {
          color: #f9b949;
        }
      }

      .cards {
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: 12px;
        padding: 10px 10px 0px 10px;

        .select_container_class_onetoone {
          cursor: pointer;
          border: 1px solid $color-yellow;
          // box-shadow: 0px 0px 5px 0px $color-yellow;
          // animation: moveBorder 4s infinite linear;
        }
        .select_container_class_presentation {
          cursor: pointer;
          border: 1px solid $color-green;
          // box-shadow: 0px 0px 5px 0px $color-yellow;
          // animation: moveBorder 4s infinite linear;
        }
        .select_container_class_sale {
          cursor: pointer;
          border: 1px solid $color-carrot;
          // box-shadow: 0px 0px 5px 0px $color-yellow;
          // animation: moveBorder 4s infinite linear;
        }

        .container_class {
          cursor: pointer;
        }
      }
    }
  }

  .input_section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 21px;
    margin-bottom: 40px;

    .input_field {
      padding: 16px 20px;
    }
    .input_container {
      margin-bottom: 0px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      // @media screen and (max-width: 400px) {
      //   justify-content: space-between;
      //   gap: 10px;
      // }

      .container_btn {
        width: 300px;
      }

      .sale_btn {
        border-radius: 16px;
        padding: 16px 0px;
        background-color: #f9b949;
        color: black;

        // @media screen and (max-width: 400px) {
        //   padding: 10px 50px;
        // }
      }

      .meeting_btn {
        border-radius: 16px;
        padding: 16px 0px;
        color: #f9b949;
        background-color: rgba(78, 78, 97, 0.4);
        border: 1px solid rgba(207, 207, 252, 0.15);

        // @media screen and (max-width: 400px) {
        //   padding: 10px 50px;
        // }
      }

      .sale_btn_2 {
        border-radius: 16px;
        padding: 16px 0px;
        background-color: #00fad9;
        border: 1px solid #00fad9;
        color: black;

        // @media screen and (max-width: 400px) {
        //   padding: 10px 50px;
        // }
      }

      .presentation_btn {
        border-radius: 16px;
        padding: 16px 0px;
        color: #00fad9;
        background-color: rgba(78, 78, 97, 0.4);
        border: 1px solid rgba(207, 207, 252, 0.15);

        // @media screen and (max-width: 400px) {
        //   padding: 10px 45px;
        // }
      }

      .allothersale_btn_container {
        width: 100%;
        // @media screen and (max-width: 400px) {
        //   width: 100%;
        //   padding: 0px;
        // }
      }

      .allothersale_btn {
        border-radius: 16px;
        padding: 16px 0px;
        background-color: #ff7966;
        border: 1px solid #ff7966;
        color: black;
      }
    }
  }

  .highlight {
    text-align: center;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid $color-grey-scale-60;
    font-family: $font-family-inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: white;
    margin: 0px 21px 0px;
  }
}

.pekaboo_main {
  justify-content: flex-start;
}
