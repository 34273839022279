@import '../../styles/variables.scss';

.main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  cursor: pointer;

  .summary_container {
    width: 100%;
    height: 82px;
    background-color: #26262f;
    border-radius: 20px;
    border: 1px solid rgba(207, 207, 252, 0.35);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    .section_left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      .icon_container {
        .icon {
          width: 24px;
          height: 24px;
        }
      }
      .title_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0px;

        .title {
          font-family: $font-family-inter;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .section_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;

      .amount_container {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;

        .empty_div {
          height: 20px;
        }

        .amount_value {
          font-family: $font-family-inter;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
        }
      }

      .icon_container {
        cursor: pointer;
        .arrowup {
          transform: rotate(180deg);
        }
      }
    }
  }

  .detail_container {
  }
}
