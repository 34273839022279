@import '../../../styles//variables.scss';

.container {
  width: 100%;
  margin-top: 4px;

  .btn {
    background-color: $color-yellow;
    border: 1px solid $color-yellow;
    border-radius: 999px;
    width: 100%;
    height: 60px;
    font-family: $font-family-inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 14px 24px;

    &:hover {
      background-color: $color-yellow;
      border: 1px solid $color-yellow;
    }

    &:active {
      background-color: $color-yellow;
      border: 1px solid $color-yellow;
    }
  }

  .disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
