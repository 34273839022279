@import '../../styles/variables.scss';

.container {
    background-color: #414152;
    border-radius: 700px;
    padding: 12px 0px 8px;

    .link_container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 52px;

        .nav_link {
            cursor: pointer;
            text-decoration: none;
            outline: none;
            padding-bottom: 8px;

            .img {
                width: 24px;
                height: 24px;
            }
        }
    }

    .active_item {
        border-bottom: 1.5px solid rgba(249, 185, 73, 1)
    }
}