@import '../../../styles/variables.scss';

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .description {
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: $color-summary-grey;
  }

  .description_total_row {
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: 900;
    line-height: 16px;
    color: white;
  }

  .description_diff_row {
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: 900;
    line-height: 16px;
    color: white;
    margin-top: 10px;
  }

  .amount {
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: $color-summary-grey;
  }

  .amount_total_row {
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: 900;
    line-height: 16px;
    color: white;
  }

  .amount_diff_row {
    font-family: $font-family-inter;
    font-size: 13px;
    font-weight: 900;
    line-height: 16px;
    color: white;
    margin-top: 10px;
  }
}
