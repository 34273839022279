 @import '../../../styles/variables.scss';

 .container {
     padding: 0px 25px;
     gap: 24px;
     height: 100vh;
     overflow-y: auto;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     background-image: url("../../../assets/png/welcomeScreenImage.png");
     background-repeat: no-repeat;
     background-position: top;
     background-size: cover;

     .content_flex {
         position: relative;
         top: 28%;

         .get_started_btn {
             font-size: 14px;
             font-weight: 600;
             margin-bottom: 12px;
         }

         .account_btn {
             background-color: rgba(172, 172, 172, 0.5);
             border: none;
             font-size: 14px;
             font-weight: 600;
             margin-bottom: 12px;

             &:active,
             &:hover {
                 background-color: rgba(172, 172, 172, 0.5);
             }
         }

         .heading {
             margin-bottom: 38px;
             font-size: 14px;
             font-weight: 600;
             color: $color-grey-white;
             text-align: center;
             width: 290px;
             padding: 0px 70px;

         }
     }

     .logo {
         width: 90.28px;
         height: 41px;
     }

 }