@import '../../styles/variables.scss';

.container {
  position: relative;
  .logout_btn {
    cursor: pointer;
    position: absolute;
    top: 38px;
    right: 15px;
    // background-color: #414152;
    background-color: transparent;
    color: white;
    border: 0px solid #414152;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    // padding: 5px 15px 8px 15px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .pdf_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    position: absolute;
    top: 38px;
    left: 15px;
    z-index: 100;
    // background-color: #414152;
    // padding: 6px 15px;
    border-radius: 4px;
    font-size: 13px;

    .back_btn {
      width: 24px;
      height: 16.85px;
      cursor: pointer;
    }

    p {
      margin: 0px;
    }
  }
  .selector {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 295px;
    left: 0;
    right: 0;
    z-index: 100;

    .select_main {
      width: 105px;
    }
  }

  .toggle_btn_employee {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 270px;
    left: 0;
    right: 0;
    z-index: 1;

    .label {
      margin: 0px 5px;
      font-size: 12px;
    }
  }

  .toggle_btn_manager {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 260px;
    left: 0;
    right: 0;
    z-index: 1;

    .label {
      margin: 0px 5px;
      font-size: 12px;
    }
  }
  .toggle_btn_gm {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 260px;
    left: 0;
    right: 0;
    z-index: 1;

    .label {
      margin: 0px 5px;
      font-size: 12px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 295px;
    left: 0;
    right: 0;
    z-index: 100;

    // padding-bottom: 28px;
    button {
      background: #3d3d46;
      border: none;
      border-radius: 16px;
      padding: 8px;
      font-size: 12px;
    }

    .select_main {
      width: 94px;
    }
  }
  .circle_progress_bar_container {
    background-color: rgba($color: #353542, $alpha: 0.5);
    border-radius: 0px 0px 24px 24px;
    padding: 55px 0px;

    .carousel_container {
      // a {
      //   height: 80px;
      //   top: 80px;
      // }

      div {
        div {
          // overflow: visible !important;
        }
      }
      .wrapper {
        .progress_bar {
          height: 192px;
        }

        .green_rating {
          color: #00fad9;
        }
        .red_rating {
          color: #ff7966;
        }
        .main_rating {
          color: #f9b949;
        }
      }
    }
  }

  .user_details {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: $font-family-inter;
    font-size: 15px;
    font-weight: bold;
    color: $color-grey-scale-30;
  }

  .sub_container {
    padding: 18px 18px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .beekaboo_card {
      border: 1.5px solid $color-green;
    }

    .onetoone_card {
      border: 1.5px solid $color-yellow;
    }
  }
}
